.identity-layout * {
  font-family: inherit !important;
}

p.label {
  font-family: inherit;
}

@media only screen and (min-width: 1024px) {
  .container {
    background-color: var(--clinical);
    border: 1px solid var(--gravel);
    -webkit-box-shadow: 1px 1px 1px 0px var(--subtle);
            box-shadow: 1px 1px 1px 0px var(--subtle);
    max-width: 414px;
  }
}

.form-label {
  color: var(--slate);
  font-family: inherit;
}

.form-label:not(:disabled):focus:not(.error) {
  border-color: var(--obsidian);
}

.form-input {
  background-color: var(--clinical);
  height: 36px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  caret-color: var(--squid-ink);
  border-radius: 5px;
  border: 1px solid var(--grey-area);
  -webkit-transition: border-color .2s ease;
  transition: border-color .2s ease;
}

.form-input::-webkit-input-placeholder {
  color: var(--concrete);
}

.form-input::-moz-placeholder {
  color: var(--concrete);
}

.form-input::-ms-input-placeholder {
  color: var(--concrete);
}

.form-input::placeholder {
  color: var(--concrete);
}

.form-input:not(:disabled):focus {
  outline: 0;
}

.form-input:not(:disabled):focus:not(.error) {
  border-color: var(--obsidian);
}

.form-input:not(:disabled):focus:not(.error) {
  border-color: var(--obsidian);
}

.form-input:hover {
  border-color: var(--concrete);
}

.form-input:disabled:hover {
  border-color: var(--grey-area);
}

.form-input.error {
  border-color: var(--red-herring);
}

.button {
  color: var(--clinical);
  background-color: var(--cornflower-blue);
  font-weight: 600;
  font-size: 16px;
  height: 56px;
  border-radius: 8px !important;
  border: none;
  white-space: nowrap;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  cursor: pointer;
}

.button:disabled {
  opacity: .4;
  pointer-events: none;
}

.button:hover:not(:disabled) {
  background-color: var(--chefchaouen);
}

span.bodySmall {
  font-family: inherit;
}

.form-footer {
  font-family: inherit;
}

.form-footer.ash {
  color: var(--ash);
}

.form-footer.obsidian {
  color: var(--obsidian);
}

a {
  text-decoration: underline;
  -webkit-transition: .3s;
  transition: .3s;
}

.oauth-button {
  border: 1px solid var(--nantucket);
  background-color: var(--clinical);
  color: var(--iron-ore);
  text-decoration: none;
  padding: 1px 6px;
}

.oauth-button:disabled {
  opacity: .4;
  pointer-events: none;
}

.oauth-button:hover:not(:disabled) {
  color: var(--iron-ore);
  border-color: var(--iron-ore);
  background-color: var(--clinical);
}

.oauth-button img {
  margin-right: 10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  vertical-align: -6px;
}

.password-container {
  position: relative;
  margin: auto;
}

.tooltip {
  margin-top: 2px;
  margin-left: 4px;
}

.password-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.tooltiptext svg {
  position: absolute;
  top: -5px;
  margin-left: -4px;
}

.tooltip .tooltiptext {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px;
  width: 190px;
  height: 70px;
  background: var(--obsidian);
  border-radius: 4px;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  font-weight: 600;
  font-size: 12.642px;
  visibility: hidden;
  color: var(--clinical);
  line-height: 19px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  margin-top: 2px;
  margin-left: -88px;
}

.password-container .inline-error {
  position: absolute;
}

.icon-e {
  right: 16px;
  margin-left: -25px;
  margin-top: 10px;
  position: absolute;
  z-index: 2;
  opacity: .4;
}

.auth-flash-error {
  color: var(--red-herring);
}

.auth-flash-error ul {
  list-style: none;
  padding: 0;
}

.auth-flash-notice {
  background-color: var(--summer-breeze);
}

.input-error {
  border-color: var(--red-herring);
}

.resend-device-code {
  color: var(--ash);
}

.device-next {
  min-width: 100px;
}

.round-gravatar {
  border-radius: 50%;
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  border-radius: 0;
}

input[type=button], input[type=submit] {
  -webkit-appearance: none;
}

.col-md-2-custom {
  padding-left: 0 !important;
}