html {
  height: 100%;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Merriweather","Palatino",Garamond,Georgia,serif;
}

@media only screen and (min-width: 1024px) {
  body {
    background-color: var(--linen) !important;
  }
}

header {
  background-color: var(--iron-ore);
}

footer {
  position: relative !important;
  margin-top: auto;
  z-index: -10;
}